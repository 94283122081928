import React from "react";
import { useTranslation } from "react-i18next";
import tw, { css } from "twin.macro";

import PartnersCarousel from "../../components/Carrousel";
import { Container } from "../../components/Container";

const LogosBlock: React.FC = ({ ...rest }) => {
  const { t } = useTranslation();

  const logos = css`
    svg {
      ${tw`fill-current text-turquoise w-40 h-40 mx-auto`}
    }
  `;

  return (
    <Container {...rest}>
      <section>
        <article id="logos" css={logos}>
          <h3 tw="text-5xl font-black text-center">{t("howItWorks.partnersHeeader")}</h3>
          <PartnersCarousel />
        </article>
      </section>
    </Container>
  );
};

export default LogosBlock;
